import { capitalizeFullName } from "@/utils/formatName";

export class Contact {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  panelist?: boolean;
  address?: string;
  id: string;
  npi: string;
  isDoctor?: boolean;

  constructor(props: any) {
    this.set(props);
  }

  static create(props: any): Contact {
    return new Contact(props)
  }

  set(props: any): void {
    this.setDefaultData();

    if (!props) return;

    this.firstName = props.firstName ?? "";
    this.lastName = props.lastName ?? "";
    this.email = props.email ?? "";
    this.phoneNumber = props.phoneNumber ?? "";
    this.panelist = props.panelist ?? false
    this.id = props.id ?? ""
    this.npi = props.npi ?? ""
    this.isDoctor = props.isDoctor ?? false
  }

  setDefaultData(): void {
    this.id = "";
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.phoneNumber = "";
    this.panelist = false;
    this.npi = "";
    this.isDoctor = false;
  }

  static createDefault(): Contact {
    return new Contact({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      panelist: false,
      npi: "",
      isDoctor: false
    });
  }

  getJsonObj() {
    return {
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
      email: this.email,
      phoneNumber: this.phoneNumber,
    }
  }

  get fullName() {
    return `${this.firstName.toLowerCase()} ${this.lastName.toLowerCase()}`
  }

  get fullNameCapitalized() {
    return capitalizeFullName(this.fullName ?? "")
  }
  
  get hasFirstName() {
    return this.firstName.trim() !== ""
  }
  
  get hasLastName() {
    return this.lastName.trim() !== ""
  }
  
  get hasEmail() {
    return this.email.trim() !== ""
  }
  
  get hasPhoneNumber() {
    return this.phoneNumber !== ""
  }

  get isPanelist(): boolean {
    return this.panelist ?? false
  }
}